export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  AddGlobalEAttributesForCart: 'specs.stores.addGlobalEAttributesForCart',
  AddExpressCheckoutToggleForCart: 'specs.stores.AddExpressCheckoutToggleForCart',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  DisplayViolations: 'specs.stores.DisplayViolations',
  UseNewCheckoutInFastFlow: 'specs.stores.UseNewCheckoutInFastFlow',
  ShouldEnableANonClickableLineItem: 'specs.stores.ShouldEnableANonClickableLineItem',
  ShouldReportIsMember: 'specs.stores.ShouldReportIsMember',
  UseExpressCheckoutButtonsLibraryForCart: 'specs.stores.UseExpressCheckoutButtonsLibraryForCart',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  UseNewCheckoutForNonStoresFlows: 'specs.stores.UseNewCheckoutForNonStoresFlows',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  ShowCheckoutButtonForExpressOnlyPaymentMethods: 'specs.stores.ShowCheckoutButtonForExpressOnlyPaymentMethods',
  UseIsPreselectedFlowOverIsPickupFlow: 'specs.stores.UseIsPreselectedFlowOverIsPickupFlow',
} as const;
